/* eslint-disable import/no-unresolved */
import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// MENU
// const BlankPage = lazy(() => import('src/pages/dashboard/blank'));
const WelcomePage = lazy(() => import('src/pages/dashboard/welcome'));
const SettingsPage = lazy(() => import('src/pages/settings/settings'));
const KategorijaList = lazy(() => import('src/pages/sifarnici/kategorija/list'));
const MarzaList = lazy(() => import('src/pages/sifarnici/marza/list'));
const ProizvodjacList = lazy(() => import('src/pages/sifarnici/proizvodjac/list'));
const UslugaList = lazy(() => import('src/pages/sifarnici/usluga/list'));
const RobaList = lazy(() => import('src/pages/sifarnici/roba/list'));
const LiftList = lazy(() => import('src/pages/sifarnici/lift/list'));
const KomitentList = lazy(() => import('src/pages/sifarnici/komitent/list'));
const UpravnikList = lazy(() => import('src/pages/sifarnici/upravnik/list'));
const RadnikList = lazy(() => import('src/pages/sifarnici/radnik/list'));
const ZahtevPonudaList = lazy(() => import('src/pages/ponuda/zahtev/list'));
const ZahtevRadniNalogList = lazy(() => import('src/pages/radniNalog/zahtev/list'));
const UgovorList = lazy(() => import('src/pages/ugovor/list'));
const UgovorForm = lazy(() => import('src/pages/ugovor/form'));
const UgovorView = lazy(() => import('src/pages/ugovor/view'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <WelcomePage />, index: true },
      { path: 'dashboard', element: <WelcomePage /> },
    ],
  },

  {
    path: 'settings',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <SettingsPage />, index: true },
      { path: 'settings', element: <SettingsPage /> },
    ],
  },

  {
    path: 'sifarnici/kategorija-list',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <KategorijaList />, index: true },
      { path: 'sifarnici/kategorija-list', element: <KategorijaList /> },
    ],
  },

  {
    path: 'sifarnici/marza-list',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <MarzaList />, index: true },
      { path: 'sifarnici/marza-list', element: <MarzaList /> },
    ],
  },

  {
    path: 'sifarnici/proizvodjac-list',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <ProizvodjacList />, index: true },
      { path: 'sifarnici/proizvodjac-list', element: <ProizvodjacList /> },
    ],
  },

  {
    path: 'sifarnici/usluga-list',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <UslugaList />, index: true },
      { path: 'sifarnici/usluga-list', element: <UslugaList /> },
    ],
  },

  {
    path: 'sifarnici/roba-list',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <RobaList />, index: true },
      { path: 'sifarnici/roba-list', element: <RobaList /> },
    ],
  },

  {
    path: 'sifarnici/lift-list',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <LiftList />, index: true },
      { path: 'sifarnici/lift-list', element: <LiftList /> },
    ],
  },

  {
    path: 'sifarnici/komitent-list',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <KomitentList />, index: true },
      { path: 'sifarnici/komitent-list', element: <KomitentList /> },
    ],
  },

  {
    path: 'sifarnici/upravnik-list',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <UpravnikList />, index: true },
      { path: 'sifarnici/upravnik-list', element: <UpravnikList /> },
    ],
  },

  {
    path: 'sifarnici/radnik-list',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <RadnikList />, index: true },
      { path: 'sifarnici/radnik-list', element: <RadnikList /> },
    ],
  },


  {
    path: 'zahtev-ponuda/zahtev-ponuda-list',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <ZahtevPonudaList />, index: true },
      { path: 'zahtev-ponuda/zahtev-ponuda-list', element: <ZahtevPonudaList /> },
    ],
  },


  {
    path: 'zahtev-radni-nalog/zahtev-radni-nalog-list',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <ZahtevRadniNalogList />, index: true },
      { path: 'zahtev-radni-nalog/zahtev-radni-nalog-list', element: <ZahtevRadniNalogList /> },
    ],
  },


  {
    path: 'ugovor',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <UgovorList />, index: true },
      { path: 'list', element: <UgovorList /> },
      { path: 'form', element: <UgovorForm /> },
      { path: 'view', element: <UgovorView /> },
    ],
  },

];
