export const UserType = {
  None: 0,
  Admin: 1,
  Radnik: 2,
  Komitent: 3,
  Upravnik: 4
}

export const JedinicaMere = {
  None: 0,
  kom: 1,
  m: 2,
  l: 3
}

export const LiftType = {
  None: 0,
  Elektricni: 1,
  Hidraulicni: 2
}

export const RadnikType = {
  None: 0,
  Serviser: 1
}

export const StatusDokumenta = {
  None: 0,
  Processing: 1,
  Zavrsen: 2,
  Storniran: 3
}

export const UpravnikType = {
  None: 0,
  DomaceLice: 1,
  ProfesionalniUpravnik: 2
}

export const UslugaType = {
  None: 0,
  Cena: 1,
  BrojRadnihSati: 2
}

export const VrstaLifta = {
  None: 0,
  Putnicki: 1,
  Teretni: 2,
  MaloTeretni: 3
}

export const NacinPlacanja = {
  None: 0,
  Virmanski: 1,
  Gotovina: 2,
  Avansno: 3
}

export const VrstaFajla = {
  Slika: 'slika'
}

export const Folder = {
  User: 'user',
  Radnik: 'radnik'
}

export const VelicinaSlike = {
  Mala: 'mala',
  Srednja: 'srednja',
  Velika: 'velika'
}

export const RemoveAction = {
  Delete: 0,
  Activate: 1,
  Deactivate: 2,
  Storniraj: 3
}
