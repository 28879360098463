export const paths = {
  maintenance: '/maintenance',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  auth: {
    login: '/auth/login',
    register: '/auth/register',
  },
};
