// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';

import { useAuthContext } from 'src/auth/hooks';

import { useSnackbar } from 'src/components/snackbar';
import { useRouter } from 'src/routes/hooks';

// routes
// import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';
import { VelicinaSlike } from '../../utils/enums.tsx'
import { getFirmaLogo } from '../../utils/utils.tsx';

// ----------------------------------------------------------------------

export default function NavUpgrade() {
  const { logout, firma } = useAuthContext();

  const router = useRouter();

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useLocales();


  const handleLogout = async () => {
    try {
      await logout();
      router.replace('/');
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('logout_error'), { variant: 'error' });
    }
  };


  return (
    <Stack
      sx={{
        px: 2,
        py: 5,
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">
        <Box sx={{ position: 'relative' }}>
          {
            (firma && firma.logo)
            ?
            <Avatar
              src={getFirmaLogo(firma?.logo, VelicinaSlike.Mala)}
              alt={firma?.imePrezime}
              sx={{
                width: 48,
                height: 48,
                border: (theme) => `solid 2px ${theme.palette.background.default}`,
              }}
            >
              {firma?.imePrezime.charAt(0).toUpperCase()}
            </Avatar>
            :
            <Avatar alt={firma?.imePrezime} sx={{ width: 48, height: 48, bgcolor: grey[400] }}>{firma?.imePrezime[0]}</Avatar>
          }

          <Label
            color={'default'}
            variant="filled"
            sx={{
              top: -6,
              px: 0.5,
              left: 40,
              height: 20,
              position: 'absolute',
              borderBottomLeftRadius: 2,
            }}
          >
            {firma?.grad}
          </Label>
        </Box>

        <Stack spacing={0.5} sx={{ mt: 1.5, mb: 2 }}>
          <Typography variant="subtitle2" noWrap>
            {firma?.naziv}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.disabled' }}>
            {firma?.adresa}
          </Typography>
        </Stack>

        <Button variant="contained" onClick={handleLogout}>
        {t('settings.logout')}
        </Button>
      </Stack>
    </Stack>
  );
}
