/* eslint-disable no-loop-func */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from 'src/utils/axios';


export interface InitState {
  isLoading: boolean,
  item: any | null,
}


function NewReducer() {
  const name = 'testSlice';


  const initialState: InitState = {
    isLoading: false,
    item: null,
  };


  const reducers = {
    start: (state: InitState) => {
      state.isLoading = true;
    },
    finish: (state: InitState, action: PayloadAction<any>) => {
      state.item = action.payload;
      state.isLoading = false;
    },
  };


  const apis = {
    calApi: () => async (dispatch: any) => {
      dispatch(actions.start());

      await axios.get('user').then(result => {
        dispatch(actions.finish(result.data.data));
      }).catch(error => {
        dispatch(actions.finish(null));
      });

    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();
