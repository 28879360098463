import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';

import testSlice from './slice/testSlice.tsx';
import removeSlice from './slice/remove/removeSlice.tsx';
import formSlice from './slice/form/formSlice.tsx';
import viewSlice from './slice/form/viewSlice.tsx';
import settings from '../pages/settings/redux/settings.tsx';
import selectAutocompleteSlice from '../components/autocomplete/selectAutocompleteSlice.tsx';

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const store = configureStore({
  reducer: {
    testSlice: testSlice.reducer,
    removeSlice: removeSlice.reducer,
    formSlice: formSlice.reducer,
    viewSlice: viewSlice.reducer,
    settings: settings.reducer,
    selectAutocompleteSlice: selectAutocompleteSlice.reducer,
  },
});

export { store };

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAppDispatch = () => useDispatch<AppDispatch>();
