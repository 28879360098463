/* eslint-disable import/no-unresolved */
/* eslint-disable eqeqeq */
/* eslint-disable no-plusplus */
/* eslint-disable no-empty */
/* eslint-disable no-else-return */
/* eslint-disable prefer-const */
/* eslint-disable arrow-body-style */
/* eslint-disable prefer-template */
import _ from 'lodash';
import numeral from 'numeral';
import { t } from 'i18next';
import TimelineDot from '@mui/lab/TimelineDot';
import { VrstaFajla, Folder, VelicinaSlike, JedinicaMere, LiftType, VrstaLifta, UslugaType, UpravnikType, RadnikType, StatusDokumenta } from './enums.tsx';

import '../assets/css/styles.css';

const WEB_URL = process.env.REACT_APP_WEB_URL;

const CHARACTERS = ['\\', ':', ';', '*', '?', '"', '<', '>', '|',];

// ----------------------------------------------------------------------

export const getFirmaLogo = (logo = null, velicinaSlike = VelicinaSlike.Srednja) => {
  return logo ? WEB_URL + VrstaFajla.Slika + '/' + Folder.User + '/' + velicinaSlike + '/' + logo : null;
}
export const getUserLogo = (logo = null, velicinaSlike = VelicinaSlike.Srednja) => {
  return logo ? WEB_URL + VrstaFajla.Slika + '/' + Folder.Radnik + '/' + velicinaSlike + '/' + logo : null;
}


export const dateTimeFormat = () => {
  return 'dd MMM yyyy HH:mm';
}
export const dateFormat = () => {
  return 'dd MMM yyyy';
}
export const timeFormat = () => {
  return 'HH:mm';
}


export const flash = (item: any, anim = 'flash-update') => {
  return new Promise<void>((resolve) => {
    if(item instanceof Array){
      item.forEach((itm, i) => {
        setTimeout(() => {
          try {
            let el = document.getElementById("row_" + itm.id);
            el?.classList.add(anim);
            setTimeout(() => {
              try {
                el?.classList.remove(anim);
              } catch (err) {}

              resolve();
            }, 1500);
          } catch (err) {}
        }, 500);
      });
    } else {
      setTimeout(() => {
        try {
          let el = document.getElementById("row_" + item.id);
          el?.classList.add(anim);
          setTimeout(() => {
            try {
              el?.classList.remove(anim);
            } catch (err) {}

            resolve();
          }, 1500);
        } catch (err) {}
      }, 500);
    }
  });
};
export const removeRow = async (rows: Array<any>, item: any) => {
  let newRows = _.cloneDeep(rows);

  if(item instanceof Array){
    newRows = newRows.filter((row) => !item.some((x) => x.id === row.id));
  } else {
    newRows = newRows.filter(x => x.id !== item.id);
  }

  await flash(item, 'flash-remove');
  return newRows;
}
export const createRow = (rows: Array<any>, item: any) => {
  let newRows = _.cloneDeep(rows);
  newRows.unshift(item);
  flash(item, 'flash-create');
  return newRows;
}
export const updateRow = (rows: Array<any>, item: any) => {
  let newRows = _.cloneDeep(rows);

  if(item instanceof Array){
    item.forEach((itm) => {
      const index = newRows.findIndex((x) => x.id === itm.id);
      if (index !== -1) {
        newRows.splice(index, 1, itm);
      }
    });

  } else {
    const index = newRows.findIndex(x => x.id === item.id);
    if(index !== -1){
      newRows.splice(index, 1, item);
    }
  }

  flash(item, 'flash-update');
  return newRows;
}


export const getUint8Array = (base64: any) => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  return new Uint8Array(byteNumbers);
}
export const downloadPDF = (name: string, byte: any, type = "application/pdf") => {
  let byteArray = getUint8Array(byte);
  let blob = new Blob([byteArray], { type });
  let url = window.URL.createObjectURL(blob);
  let link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  link.remove();
}
export const viewPDF = (byte: any) => {
  let byteArray = getUint8Array(byte);
  const blob = new Blob([byteArray], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  window.open(url, '_blank');
}
export const downloadCSV = (name: string, byte: any, type = "text/csv") => {
  let blob = new Blob([byte], { type });
  let url = window.URL.createObjectURL(blob);
  let link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export const escapeChar = (e: any = null, char: any = null) => {
  if (e && char) {
      if (char === e.key) {
          e.preventDefault();
          e.stopPropagation();
          return true;
      }
  }

  return false;
}
export const escapeChars = (e: any = null) => {
  if (e) {
      if (CHARACTERS.includes(e.key)) {
          return escapeChar(e, e.key);
      }
  }

  return false;
}

export const formatNumber = (numb = 0, format = '0,0.00', currency = ' RSD') => {
  try {
    return numeral(numb).format(format) + currency;
  } catch (e) {
    return numb;
  }
}
export const formatNosivost = (numb = 0, jm = 'Kg') => {
  return numb + ' ' + jm;
}
export const formatPhoneNumnber = () => {
  return "069/99-99-999";
}
export const formatMinToHours = (min = null) => {
  let numb = convertMinToHours(min);
  return formatNumber(numb, '0.[00]', ' h');
}

export const convertMinToHours = (min: any = null) => {
  let numb = 0;

  if(min){
    try {
      numb = (parseInt(min, 10) / 60);
    } catch (e) {
      numb = 0;
    }
  } else {
    numb = 0;
  }

  return numb;
}
export const convertHoursToMin = (h: any = null) => {
  let numb = 0;

  if(h){
    try {
      numb = (parseFloat(h) * 60);
    } catch (e) {
      numb = 0;
    }
  } else {
    numb = 0;
  }

  return numb;
}

export const twoDecimalValidation = (value: any) => {
  if (!value) return true;
  return /^\d+(\.\d{1,2})?$/.test(value);
}


export const getJM = (jedinicaMere = JedinicaMere.None) => {
  if(jedinicaMere == JedinicaMere.kom){
    return t('enums.jedinicaMere.kom');
  } else if(jedinicaMere == JedinicaMere.m){
    return t('enums.jedinicaMere.m');
  } else if(jedinicaMere == JedinicaMere.l){
    return t('enums.jedinicaMere.l');
  } else {
    return '-';
  }
}
export const getLiftType = (liftType = LiftType.None) => {
  if(liftType == LiftType.Elektricni){
    return t('enums.liftType.elektricni');
  } else if(liftType == LiftType.Hidraulicni){
    return t('enums.liftType.hidraulicni');
  } else {
    return '-';
  }
}
export const getVrstaLifta = (vrstaLifta = VrstaLifta.None) => {
  if(vrstaLifta == VrstaLifta.Putnicki){
    return t('enums.vrstaLifta.putnicki');
  } else if(vrstaLifta == VrstaLifta.Teretni){
    return t('enums.vrstaLifta.teretni');
  } else if(vrstaLifta == VrstaLifta.MaloTeretni){
    return t('enums.vrstaLifta.maloTeretni');
  } else {
    return '-';
  }
}
export const getUslugaType = (uslugaType = UslugaType.None) => {
  if(uslugaType == UslugaType.Cena){
    return t('enums.uslugaType.cena');
  } else if(uslugaType == UslugaType.BrojRadnihSati){
    return t('enums.uslugaType.brojRadnihSati');
  } else {
    return '-';
  }
}
export const getUpravnikType = (upravnikType = UpravnikType.None) => {
  if(upravnikType == UpravnikType.DomaceLice){
    return t('enums.upravnikType.domaceLice');
  } else if(upravnikType == UpravnikType.ProfesionalniUpravnik){
    return t('enums.upravnikType.profesionalniUpravnik');
  } else {
    return '-';
  }
}
export const getRadnikType = (radnikType = RadnikType.None) => {
  if(radnikType == RadnikType.Serviser){
    return t('enums.radnikType.serviser');
  } else {
    return '-';
  }
}
export const getStatusDokumenta = (statusDokumenta = StatusDokumenta.None) => {
  if(statusDokumenta == StatusDokumenta.Processing){
    return t('enums.statusDokumenta.processing');
  } if(statusDokumenta == StatusDokumenta.Zavrsen){
    return t('enums.statusDokumenta.zavrsen');
  } if(statusDokumenta == StatusDokumenta.Storniran){
    return t('enums.statusDokumenta.storniran');
  } else {
    return '-';
  }
}
export const getTabColorStatusDokumenta = (statusDokumenta = 'all') => {
  if(statusDokumenta === 'active'){
    return 'info';
  } if(statusDokumenta === 'processing'){
    return 'warning';
  } if(statusDokumenta === 'finished'){
    return 'success';
  } if(statusDokumenta === 'cancelled'){
    return 'error';
  } else {
    return 'default';
  }
}
export const getColorStatusDokumenta = (statusDokumenta = StatusDokumenta.None) => {
    if(statusDokumenta == StatusDokumenta.Processing){
      return 'warning';
    } if(statusDokumenta == StatusDokumenta.Zavrsen){
      return 'success';
    } if(statusDokumenta == StatusDokumenta.Storniran){
      return 'error';
    } else {
      return 'default';
    }
}
export const getTimelineColorStatusDokumenta = (statusDokumenta = StatusDokumenta.None) => {
    if(statusDokumenta == StatusDokumenta.Processing){
      return <TimelineDot color={'warning'} />
    } if(statusDokumenta == StatusDokumenta.Zavrsen){
      return <TimelineDot color={'success'} />
    } if(statusDokumenta == StatusDokumenta.Storniran){
      return <TimelineDot color={'error'} />
    } else {
      return <TimelineDot color={'grey'} />
    }
}
